import React from "react";
import "../style/Contact.css";
import contact from '../assets/images/ganesha-kundli.png'


const Contact = () => {
  function linkwhatsapp() {
    window.open('https://wa.link/sfydz9','_blank');
  }
  return (
    <>
      <div className="contact_section">
        <div>
          <div className='subheader-details'>
            <h4>Contact Us</h4>
            <p>तुमच्या कोणत्याही समस्येसाठी आजच आचार्य पंडित व्हि . एस शास्त्री
              यांना संपर्क करा</p>
          </div>
          <div className="contact_main_section">
            <div className="container">
              <div className="row-contact">
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="contact_box_wrapper">
                    <div className="contact_img_wrapper">
                      <img src={contact} alt="" />
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="contact_sub_part">
                          <h4>Mobile No</h4>
                          <p>7387408015</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="contact_sub_part">
                          <h4>Email</h4>
                          <p>enquiry@vsshastri.in</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="contact_sub_part">
                          <h4>Address</h4>
                          <p>trimbakeshwar Nashik</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="contact_box_wrapper">
                    <div className="contact_right_part">
                      <h3>Get In Touch</h3>
                      <p>
                        Have an inquiry or some feedbak for us? Fill out the
                        form below to contact our team.
                      </p>
                    </div>
                    <div className="contact_form_section">
                      <div>
                        <label className="form_label">Name</label>
                        <input
                          type="text"
                          placeholder="Enter your Name"
                          className="form_input"
                        />
                      </div>
                      <div>
                        <label className="form_label">Email</label>
                        <input
                          type="email"
                          placeholder="Enter a valid email address"
                          className="form_input"
                        />
                      </div>
                      <div>
                        <label className="form_label">How can we help?</label>
                        <textarea
                          type="text"
                          className="form_input"
                          rows={4}
                        ></textarea>
                      </div>
                      <div className="submit_btn_wrapper">
                        <button className="button-62" onClick={linkwhatsapp}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
