import React, { lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import Service from './pages/Service'
import SpecialPuja from './pages/SpecialPuja'
import Stones from './pages/Stones'
import PremSandharbhat from './pages/Premsandharbhat'

export const App = () => {
  return (
    <>
      <section>
        <Header />
      </section>
      <section id="home">
        <Home />
      </section>
      <section id="service">
        <Service />
      </section>
      <section id="gallery">
        <SpecialPuja />
      </section>
      <section>
      <PremSandharbhat />
      </section>
      <section id="stone">
        <Stones />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
};
