import React from 'react'
import '../style/Stones.css'
import { Row, Col, Container } from 'react-bootstrap'
import { IMAGES } from '../../utils'
import p1 from '../assets/images/payment2.jpg'
import p2 from '../assets/images/Payment1.jpg'



const Stones = () => {

    function linkwhatsapp() {
        window.open('https://wa.link/sfydz9', '_blank');
    }
    return (
        <>
            <Container fluid>
                <div className='subheader-details'>
                    <h4>रत्न विवरण</h4>
                    <p>पुजा विधान आणि मंत्रासह प्रमाणित आणि मूळ राशी रत्न (रुबी, मोती, पन्ना, कोरल, पुष्कराज, नीलम, डायमंड, गोमेद, लसूण) कोरल, एमराल्ड, क्रिस्टल आणि मर्क्युरी गणपती उपलब्ध
                        2 मुखी ते 14 मुखी रुद्राक्ष शुद्धीकरण विधी आणि मंत्रांसह.</p>
                </div>
                <Row className='stone-row'>
                    <Col sm="12" md="6" lg="3">
                        <div class="container">
                            <div class="card">
                                <div class="imgBx">
                                    <img src={IMAGES.Ruby} style={{ width: '100px' }} />
                                </div>
                                <div class="contentBx">
                                    <h2>Manikya (रुबी)</h2>
                                    <p style={{ color: "white", fontSize: '14px' }}>✔ रुबी नातेसंबंध मजबूत करते ✔ व्यावसायिक उत्कृष्टता आणि सद्भावना निर्माण करते ✔ मानसिक शांती आणि भावनिक उपचार सुनिश्चित करते ✔ माणिक वाईटांपासून संरक्षण करण्यास मदत करतात ✔ रुबीमध्ये बरे करण्याचे गुणधर्म आणि आरोग्य आशीर्वाद आहेत</p>
                                    <p style={{ color: 'white', fontSize: '2rem' }}>&#8377;1,758</p>
                                    <a onClick={linkwhatsapp}>Buy Now</a>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col sm="12" md="6" lg="3">
                        <div class="container">
                            <div class="card">
                                <div class="imgBx">
                                    <img src={IMAGES.Pukhraj} style={{ width: '150px' }} />
                                </div>
                                <div class="contentBx">
                                    <h2>Pukhraj (पुष्कराज)</h2>
                                    <p style={{ color: "white", fontSize: '14px' }}>✔ मानसिक स्पष्टता वाढवा ✔ झोपेची गुणवत्ता चांगली मिळवा ✔ तणावाची पातळी कमी करा ✔ अधिक ऊर्जा मिळवा ✔ रोगप्रतिकार प्रणाली कार्य वाढवा ✔ रक्ताभिसरण सुधारणे ✔ अधिक आध्यात्मिक जागरूकता मिळवा</p>
                                    <p style={{ color: 'white', fontSize: '2rem' }}>&#8377;2,199</p>
                                    <a onClick={linkwhatsapp}>Buy Now</a>
                                </div>

                            </div>
                        </div>
                    </Col> <Col sm="12" md="6" lg="3">
                        <div class="container">
                            <div class="card">
                                <div class="imgBx">
                                    <img src={IMAGES.Panna} style={{ width: '100px' }} />
                                </div>
                                <div class="contentBx">
                                    <h2>Panna (पन्ना)</h2>
                                    <p style={{ color: 'white', fontSize: '14px' }}>✔ हे तुम्हाला हुशार आणि बौद्धिक बनवते ✔ तुमची सर्जनशीलता वाढवते ✔ आर्थिक वाढीस मदत होते ✔ संवाद सुधारतो ✔ शारीरिक स्वास्थ्य आणि शक्ती सुधारते ✔ तुम्हाला नाव आणि प्रसिद्धी मिळविण्यात मदत करते</p>
                                    <p style={{ color: 'white', fontSize: '2rem' }}>&#8377;5000</p>
                                    <a onClick={linkwhatsapp}>Buy Now</a>
                                </div>

                            </div>
                        </div>
                    </Col> <Col sm="12" md="6" lg="3">
                        <div class="container">
                            <div class="card">
                                <div class="imgBx">
                                    <img src={IMAGES.Neelam} style={{ width: '100px' }} />
                                </div>
                                <div class="contentBx">
                                    <h2>Neelam (नीलम)</h2>
                                    <p style={{ color: "white", fontSize: '14px' }}>✔ निळ्या नीलम रत्नाचा प्रभाव त्वरित जाणवू शकतो. नीलम दगडाची अंगठी घातल्यानंतर केवळ एका दिवसात तुम्हाला संपत्ती, नशीब, पदोन्नती आणि संधी देऊ शकते. ✔ नीलम स्टोन अंगठी शत्रू, वाईट नजर, हेक्सिंग, मत्सर यापासून संरक्षण करण्यास मदत करते</p>
                                    <p style={{ color: 'white', fontSize: '2rem' }}>&#8377;2,051</p>
                                    <a onClick={linkwhatsapp}>Buy Now</a>

                                </div>

                            </div>
                        </div>
                    </Col>

                </Row>
                <Row className='stone-row'>
                    <Col sm="12" md="6" lg="3">
                        <div class="container">
                            <div class="card">
                                <div class="imgBx">
                                    <img src={IMAGES.Heera} style={{ width: '80px' }} />
                                </div>
                                <div class="contentBx">
                                    <h2>Heera (हिरा)</h2>
                                    <p style={{ color: "white", fontSize: '14px' }}>जर एखाद्या व्यक्तीच्या लग्नाला उशीर होत असेल किंवा लग्नात सर्व प्रकारचे अडथळे येत असतील तर तुम्ही ज्योतिषाचा सल्ला घेऊन हिरा धारण करू शकता. हिरा धारण केल्याने व्यक्तीच्या आर्थिक समस्याही कमी होतात. याशिवाय ज्यांना कला, चित्रपट आणि संगीत क्षेत्रात यश मिळवायचे आहे त्यांनी हिरा घालू शकता.</p>
                                    <p style={{ color: 'white', fontSize: '2rem' }}>&#8377;1,500</p>
                                    <a onClick={linkwhatsapp}>Buy Now</a>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col sm="12" md="6" lg="3">
                        <div class="container">
                            <div class="card">
                                <div class="imgBx">
                                    <img src={IMAGES.Moti} style={{ width: '80px' }} />
                                </div>
                                <div class="contentBx">
                                    <h2>Moti (मोती)</h2>
                                    <p style={{ color: "white", fontSize: '14px' }}>✔ हे चंद्राचे दुष्परिणाम दूर करते, मन मजबूत करते आणि तणावग्रस्त माणसाच्या जीवनात भावनिक संतुलन आणते. ✔ हे झोपेच्या दरम्यान अस्वस्थता कमी करण्यास आणि निद्रानाश बरे करण्यास मदत करते. ✔ मोती उदासीनता, मानसिक विकार बरे करण्यास, स्मरणशक्ती सुधारण्यास मदत करते.</p>
                                    <p style={{ color: 'white', fontSize: '2rem' }}>&#8377;900</p>
                                    <a onClick={linkwhatsapp}>Buy Now</a>
                                </div>

                            </div>
                        </div>
                    </Col> <Col sm="12" md="6" lg="3">
                        <div class="container">
                            <div class="card">
                                <div class="imgBx">
                                    <img src={IMAGES.Moonga} style={{ width: '80px' }} />
                                </div>
                                <div class="contentBx">
                                    <h2>Moonga (मुंगा)</h2>
                                    <p style={{ color: "white", fontSize: '14px' }}>मुंग्याचा दगड परिधान करणाऱ्याच्या वैवाहिक जीवनाला आणि जोडीदाराच्या दीर्घायुष्याला बळ देतो. ✔ रत्न धारण केल्याने परिधान करणार्‍यांचा आत्मविश्वास वाढेल आणि ते धैर्यवान बनतील. ✔ हे शारीरिक आरोग्यासाठी फायदेशीर आहे आणि अडथळे दूर करण्यास देखील मदत करते.</p>
                                    <p style={{ color: 'white', fontSize: '2rem' }}>&#8377;3000</p>
                                    <a onClick={linkwhatsapp}>Buy Now</a>
                                </div>

                            </div>
                        </div>
                    </Col> <Col sm="12" md="6" lg="3">
                        <div class="container">
                            <div class="card">
                                <div class="imgBx">
                                    <img src={IMAGES.Gomedh} style={{ width: '80px' }} />
                                </div>
                                <div class="contentBx">
                                    <h2>Gomedh (गोमेद)</h2>
                                    <p style={{ color: "white", fontSize: '14px' }}>संशोधन उद्योगातील विद्यार्थी आणि व्यावसायिक दोघांसाठी हे खरोखर फायदेशीर आहे. ✔ हेसोनाइट एखाद्याच्या करिअरमध्ये प्रगती करते आणि त्यांची सामाजिक आणि आर्थिक स्थिती सुधारते. ✔ जोडपे त्यांच्या वैवाहिक जीवनात शांती आणि आनंद आणण्यासाठी प्रेम, सुसंवाद आणि शांतता वाढवण्यासाठी हेसोनाइट गोमेड स्टोन घालू शकतात.</p>
                                    <p style={{ color: 'white', fontSize: '2rem' }}>&#8377;1,251</p>
                                    <a onClick={linkwhatsapp}>Buy Now</a>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* <Container className='payment-section' fluid>
                <Row className='payment-row'>
                    <Col sm={12} md={6} lg={6} className='payment-col'>
                        <img src={p1} className='qr-image' />
                    </Col>
                    <Col sm={12} md={6} lg={6} className='payment-col'>
                        <img src={p2} className='qr-image' />
                    </Col>
                </Row>
            </Container> */}
        </>

    )
}

export default Stones