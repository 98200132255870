import React from "react";
import "../style/Service.css";
import { IMAGES } from "../../utils";

const Service = () => {
  const services = [
    {
      src: IMAGES.Jyotishshashtra,
      heading: "ज्योतिषशास्त्र",
      text: "आयुष्यात अनेकवेळा असे घडते की आपण कठोर परिश्रम करूनही आपल्याला अपेक्षित यश मिळत नाही, विनाकारण कलंक लागतो, आपल्याला वारंवार आजार होतात, अशा वेळी मन खूप विचलित होते, निराश होते, काही समजत नाही. . अशा वेळी ‘सूर्य’ सारख्या समस्यांच्या रूपाने अंधार दूर करण्यात ज्योतिषशास्त्र समर्थ आहे. ज्योतिषशास्त्राच्या माध्यमातून या समस्यांची कारणे शोधून, प्रतिकूल ग्रहांचे शांती-उपाय करून, अनुकूल ग्रहांचे बळकटीकरण करून आपण आपले जीवन भौतिक, आर्थिक आणि आध्यात्मिकदृष्ट्या पूर्ण करू शकतो.",
    },
    {
      src: IMAGES.Ratnashastra,
      heading: "रत्न रहस्य",
      text: "जन्मपत्रिकेत असलेल्या ग्रहांच्या प्रभावापासून आपण सुटू शकत नाही, करक ग्रहांच्या दशामध्ये आपण विलासी आणि आनंदी जीवन जगतो आणि कारक नसलेल्या ग्रहांच्या दशामध्ये दुःख आणि संघर्ष सहन करतो. अशा वेळी ग्रहांना अनुकूल बनवण्यासाठी प्रातिनिधिक रत्नांचा आधार घ्यावा लागतो, प्रत्येक ग्रहाला एक प्रातिनिधिक रत्न असते. रत्न हे शक्तिवर्धक असतात जे आपल्या ग्रहांना विशेष शक्ती देतात. रत्नांना मंत्रोच्चार करून विशिष्ट क्षणी धारण केल्यास आणि त्यांच्याशी संबंधित मंत्रांचा जप केल्यास अलौकिक यश प्राप्त होते.",
    },
    {
      src: IMAGES.janmkundali,
      heading: "जनम कुंडली",
      text: "पंडित विनोद जी शास्त्री तुमच्या पत्रिकांचे विश्लेषण करून तुमच्या भविष्यासाठी सर्वोत्तम उपाय देतात आणि त्यानुसार तुमच्या जीवनात प्रगती कशी करावी याचे मार्गदर्शन करतात.",
    },
    {
      src: IMAGES.vastushastra,
      heading: "वास्तुशास्त्र",
      text: "पंडित विनोद जी शास्त्री तुम्हाला तुमच्या कामाच्या ठिकाणी आणि घराचे परिपूर्ण वातावरण तयार करण्यात मदत करेल जे तुमचे भविष्य घडवण्यात मोठी भूमिका बजावेल.",
    },
    {
      src: IMAGES.hastreshashastra,
      heading: "हस्तरेषाशास्त्र",
      text: "नशीब संपले? तुमच्या व्यवसायात प्रचंड नुकसान झाले आहे? तुमच्या परीक्षेत अपेक्षित निकाल मिळवण्यात अयशस्वी झालात? तुमच्या सर्व अडथळ्यांवर आमच्याकडे उपाय आहेत. ग्रहांची स्थिती सध्या तुमच्या दयेवर नाही. प्रख्यात ज्योतिषी पंडित विनोद जी शास्त्री तुम्हाला सततच्या अडथळ्यातून सावरण्यास मदत करू शकतात. हस्तरेषाशास्त्रातील त्यांचा अनुभव तुम्हाला भारनियमनाबद्दल वारंवार समजून घेण्यास अनुमती देईल .",
    },
  ];
  return (
    <>
      <div className="service_section">
        <div>
          <div className='subheader-details'>
            <h4>Our Services</h4>
            <p>आमची सेवा आपल्या सर्व अडचणीं वर उपाय देण्यास मार्गदर्शन करते.</p>
          </div>
          <div className="service_main_section">
            <div className="row">
              {services.map((item) => (
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="service_card_wrapper">
                    <img src={item.src} alt="" />
                    <div>
                      <h4>{item.heading}</h4>
                      <p>{item.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
