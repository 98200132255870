const IMAGES = {
  ContactImg: require("../app/assets/images/b649a9ee-bbda-4f6e-a251-b396599e25b9/anmol.jpg"),
  AboutImg: require("../app/assets/images/about_img.jpeg"),
  AboutImg2: require("../app/assets/images/about_img2.png"),
  Jyotishshashtra: require("../app/assets/images/Jyotishshastra.jpg"),
  Ratnashastra: require("../app/assets/images/Ratnashastra.png"),
  hastreshashastra: require("../app/assets/images/hastreshashastra.jpg"),
  janmkundali: require("../app/assets/images/janmkundali.jpg"),
  vastushastra: require("../app/assets/images/vastushastra.jpg"),
  Homeicon1: require("../app/assets/images/1-2.png"),
  Homeicon2: require("../app/assets/images/2-2.png"),
  Homeicon3: require("../app/assets/images/3-2.png"),
  gallery1: require("../app/assets/images/b649a9ee-bbda-4f6e-a251-b396599e25b9/IMG-20231101-WA0009.jpg"),
  gallery2: require("../app/assets/images/b649a9ee-bbda-4f6e-a251-b396599e25b9/IMG-20231101-WA0012.jpg"),
  gallery3: require("../app/assets/images/b649a9ee-bbda-4f6e-a251-b396599e25b9/IMG-20231101-WA0013.jpg"),
  gallery4: require("../app/assets/images/b649a9ee-bbda-4f6e-a251-b396599e25b9/IMG-20231101-WA0015.jpg"),
  gallery5: require("../app/assets/images/b649a9ee-bbda-4f6e-a251-b396599e25b9/IMG-20231101-WA0017.jpg"),
  gallery6: require("../app/assets/images/b649a9ee-bbda-4f6e-a251-b396599e25b9/IMG-20231101-WA0018.jpg"),
  Ruby: require("../app/assets/images/7454c1bdc10b6047038b42a1717bc992-removebg-preview.png"),
  Pukhraj: require("../app/assets/images/51G0n4eDdcL._SX679_-removebg-preview.png"),
  Neelam: require("../app/assets/images/41j6iKILYnL._AC_UL320_-removebg-preview.png"),
  Panna: require("../app/assets/images/panna.png"),
  Heera: require("../app/assets/images/319pFLUkZyL._AC_UL320_-removebg-preview.png"),
  Moti: require("../app/assets/images/31aZPM6PGaL._AC_UL320_-removebg-preview.png"),
  Moonga: require("../app/assets/images/41FIBrETemL._AC_UL320_-removebg-preview.png"),
  Gomedh: require("../app/assets/images/81g5cCX9A0L._AC_UL320_-removebg-preview.png"),

};

export { IMAGES };


const mobileNumber = '7387408015'
export default function handleDial() {
  window.location.href = `tel:${mobileNumber}`;
}
