import React from 'react'
import '../style/Home.css'
import { Container, Row, Col } from 'react-bootstrap'
import handleDial, { IMAGES } from '../../utils'
import { Link } from 'react-scroll'



const Home = () => {
  return (
    <>
      <div className='home-container'>
        <div className='home-content'>
          <h5>Pandit V S Shashtri</h5>
          <h4>जन्म पत्रिका | वास्तुशत्र | व्यवसाय वाढ</h4>
          <div>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              <a href='https://wa.link/sfydz9'><button className='button-62'>Enquire Now</button></a>
            </Link>

            <button className='button-62' onClick={handleDial}>Contact Us</button>
          </div>
        </div>
      </div >
      <Container fluid className='home-Why-section'>
        <h4>Why Choose Us</h4>
        <h5>तुम्हाला अपवादात्मक सेवा प्रदान करण्याच्या आमच्या क्षमतेवर आमचा ठाम विश्वास आहे.</h5>
        <Row className='why-section-row'>
          <Col sm={12} md={12} lg={4} className='why-section-coloums'>
            <div className='contents'>
              <img src={IMAGES.Homeicon1} />
              <h6>अचूक उपचार उपाय</h6>
            </div>
          </Col>
          <Col sm={12} md={12} lg={4} className='why-section-coloums'>
            <div className='contents'>
              <img src={IMAGES.Homeicon2} />
              <h6>गोपनीयतेची हमी</h6>
            </div>
          </Col>
          <Col sm={12} md={12} lg={4} className='why-section-coloums'>
            <div className='contents'>
              <img src={IMAGES.Homeicon3} />
              <h6>300+ आनंदी ग्राहक</h6>
            </div>
          </Col>
        </Row>
      </Container>

    </>
  )
}

export default Home