import React from "react";
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css';
import { App } from './app/App'
import './index.css'
import './app/assets/font/DevanagariMarathi-Regular.ttf'
import './app/assets/font/Poppins-Regular.ttf'
import './app/assets/font/Poppins-Medium.ttf'
import { BrowserRouter } from "react-router-dom";



ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);