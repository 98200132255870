import React from 'react'
import '../style/SpecialPuja.css'
import { Container, Col, Row } from 'react-bootstrap'
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { IMAGES } from '../../utils';


const galleryImage = [
    { id: "1", image: IMAGES.gallery1 },
    { id: "2", image: IMAGES.gallery2 },
    { id: "3", image: IMAGES.gallery3 },
    { id: "4", image: IMAGES.gallery4 },
    { id: "5", image: IMAGES.gallery5 },
    { id: "6", image: IMAGES.gallery6 },
];


const SpecialPuja = () => {
    return (
        <Container fluid>
            <div className='subheader-details'>
                <h4>विशेष पूजा</h4>
                <p>नारायण नागबली, कालसर्पयोग विधी, नक्षत्र शांती योगशांती अशा सर्व धार्मिक पद्धतीच्या विधी पूजा पाठ शास्त्रश्रोत पद्धतीने करून दिल्या जातील.</p>
            </div>
            <Row className='special-puja-row'>
                <Gallery>
                    {galleryImage.map((image) => {
                        return (
                            <Col sm={12} md={6} lg={4} key={image.id} className='special-puja-col'>
                                <Item
                                    original={image.image}
                                    thumbnail={image.image}
                                    width="800"
                                    height="600"
                                    
                                >
                                    {({ ref, open }) => (
                                        <img
                                            src={image.image}
                                            onClick={open}
                                            ref={ref}
                                            className="galleryimg"
                                        />
                                    )}
                                </Item>
                            </Col>
                        );
                    })}
                </Gallery>
            </Row>
        </Container>

    )
}

export default SpecialPuja