import React from 'react';
import '../style/Premsandharbhat.css';
import kal from '../assets/images/kal.jpeg';
import laxmi from '../assets/images/laxmi.jpeg';
import rudrax from '../assets/images/rudrax.jpg';
import grah from '../assets/images/9 grah.jpg';

const Premsandharbhat = () => {
  return (
    <div className="prem-container">
      <h1>प्रेम संदर्भात निराश झालेल्या व्यक्तींनी शास्त्रीजींना अवश्य कॉल करा</h1>
      <div className="yantra-cards">
        <div className="card">
          <img src={kal} alt="कालसर्प यंत्र" />
          <p>कालसर्प यंत्र</p>
        </div>
        <div className="card">
          <img src={laxmi} alt="लक्ष्मी कुबेर यंत्र" />
          <p>लक्ष्मी कुबेर यंत्र</p>
        </div>
        <div className="card">
          <img src={rudrax} alt="रुद्राक्ष" />
          <p>रुद्राक्ष (५ मुखी ते ९ मुखी)</p>
        </div>
        <div className="card">
          <img src={grah} alt="९ ग्रह यंत्र" />
          <p>९ ग्रह यंत्र</p>
        </div>
      </div>
      <div className="issues-section">
        <h2>समस्यांसाठी उपाय आणि मार्गदर्शन</h2>
        <ul>
          <li>पती-पत्नीमध्ये वाद</li>
          <li>विवाह विलंब</li>
          <li>शिक्षणात व नोकरीत अडचण</li>
          <li>घरामध्ये पैसे न टिकणे</li>
          <li>संतती मध्ये अडचण</li>
          <li>व्यवसायात आर्थिक अडचण</li>
        </ul>
        <p>एक असा मनुष्य नाही की ज्याला समस्या नाही, आणि अशी समस्या नाही की ज्याला उपाय नाही.</p>
      </div>
    </div>
  );
};

export default Premsandharbhat;
