import React, { useState } from 'react'
import '../style/Header.css'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-scroll'


const Header = () => {
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = () => {
    setExpanded(false);
  };

  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-100px";
    }
    prevScrollpos = currentScrollPos;
  }
  return (
    <>
      <Navbar className='Site-Navigation' collapseOnSelect expand="lg" fixed='top' variant='dark' id='navbar' expanded={expanded}>
        <div className='site-mobile-view'>
          <Navbar.Brand className='PName' href="home">Pandit V S Shashtri</Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-nav-bar' onClick={() => setExpanded(!expanded)} />
        </div>
        <div>
          <Navbar.Collapse id='responsive-nav-bar'>
            <Nav className="nav-content">
              <Link onClick={() => {
                handleLinkClick();
              }}
                to="home"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                className='nlink'>
                Home
              </Link>
              <Link onClick={() => {
                handleLinkClick();
              }} to="service"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className='nlink'>
                Service
              </Link>
              <Link onClick={() => {
                handleLinkClick();
              }}
                to="gallery"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                className='nlink'>
                Gallery
              </Link>
              <Link onClick={() => {
                handleLinkClick();
              }}
                to="stone"
                spy={true}
                smooth={true}
                offset={-60}
                duration={500}
                className='nlink' >
                Stone
              </Link>
              <Link onClick={() => {
                handleLinkClick();
              }}
                to="about"
                spy={true}
                smooth={true}
                offset={10}
                duration={500}
                className='nlink'>
                About
              </Link>
              <Link onClick={() => {
                handleLinkClick();
              }}
                to="contact"
                spy={true}
                smooth={true}
                offset={10}
                duration={500}
                className='nlink'>
                Contact
              </Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

    </>
  )
}

export default Header


